import { cloneDeep } from 'lodash'
import { CCell, CTr } from '../../stock/stockscreen/component/Wrapper'
import { HeaderData } from './model'
import { ReactNode, useState } from 'react'
import { Col, Row } from 'antd'
import Arrow_down from '../../../assets/icons/arrow-down.svg'
import Arrow_down_gray from '../../../assets/icons/arrow-down-gray.svg'

export const Header = (props: {
  header: HeaderData[]
  rowHeaderClassName?: string
  hasAction?: boolean
  expandColumnName?: string
  style?: {
    showBorder?: boolean
    background?: string
  }
}) => {
  const header = cloneDeep(props.header)
  if (props.hasAction) {
    header.push({
      label: 'Action',
      name: 'action',
      headerStyle: {
        headerCellClassName: 'w-1/12 bd-left-header-history-table ',
      },
    })
  }
  const [sortedList, setSortedList] = useState<string[]>([])

  return (
    <CTr
      isHeader={true}
      className={`sticky top-0 z-10 ${props?.rowHeaderClassName || ''}`}
    >
      {
        <CCell
          className={`whitespace-nowrap 
            ${
              props.style?.showBorder
                ? 'bd-bot-header-table bd-right-header-table '
                : ' '
            } 
            text-center px-0 
            ${props.style?.background || ''}  
            w-5p`}
        >
          <span className="text-header-table">{props.expandColumnName}</span>
        </CCell>
      }
      {header.map((item, idx) => {
        const { sort, name } = item
        const sorted = sortedList.includes(name)
        return (
          <CCell
            key={idx}
            className={`whitespace-nowrap
              ${
                props.style?.showBorder
                  ? 'bd-bot-header-table bd-right-header-table'
                  : ''
              }  

              ${item?.headerStyle?.headerCellClassName || ''} 
              ${props.style?.background || ''}  
              `}
          >
            {sort && (
              <Row align={'middle'} gutter={4}>
                <Col>
                  <p className="text-header-table">{item.label}</p>
                </Col>
                <Col
                  className="cursor-pointer"
                  onClick={() => {
                    let sortedListClone = cloneDeep(sortedList)
                    if (sorted) {
                      sortedListClone = sortedListClone.filter(
                        (currentName) => currentName !== name
                      )
                      setSortedList(sortedListClone)
                      sort.onRemoveSort(name)
                      return
                    }
                    sortedListClone = []
                    sortedListClone.push(name)
                    setSortedList(sortedListClone)
                    sort.onSort(name)
                  }}
                >
                  {sorted && (
                    <img
                      src={Arrow_down}
                      style={{
                        width: 14,
                        height: 14,
                        transform: `translateY(2px) rotateX(180deg)`,
                      }}
                    />
                  )}
                  {!sorted && (
                    <img
                      src={Arrow_down_gray}
                      style={{
                        width: 14,
                        height: 14,
                        transform: `translateY(2px)`,
                      }}
                    />
                  )}
                </Col>
              </Row>
            )}
            {!sort && (
              <p className="text-header-table inline-block w-fit">
                {item.label}
              </p>
            )}
          </CCell>
        )
      })}
    </CTr>
  )
}
