import { cloneDeep } from 'lodash'
import {
  DataTableParam,
  TitleParam,
} from '../../stock/stockscreen/component/model'
import { CellData, HeaderData, HistoryLineData, LineData } from './model'

export function mergeDataWithHeader(
  header: HeaderData[],
  data: LineData[],
  hideCols: string[],
  isMerge: boolean
) {
  let dataClone = groupsLines(cloneDeep(data), isMerge)
  dataClone = dataClone.map((line) => {
    line.data = line.data.filter((item) => !hideCols.includes(item.name))
    line.data = header.map((col) => {
      const cellCorrespond = line.data.find((cell) => cell.name === col.name)
      if (!cellCorrespond) return { name: col.name, value: '' }

      const formatter = col.format?.formatFunction

      if (formatter)
        cellCorrespond.value = formatter(cellCorrespond.value) || ''

      cellCorrespond.cellClassName = col?.bodyStyles?.cellClassName
      return cellCorrespond
    })
    hiddenCellBaseOnHeader(line, header)

    return line
  })

  return dataClone
}

function groupsLines(data: LineData[], isMerge?: boolean) {
  const dataClone = cloneDeep(data)
  const result: LineData[] = []
  const parentLines = dataClone.filter((line) => !line.parentLineId)
  const childrenLines = dataClone.filter((line) => line.parentLineId)

  for (let i = 0; i < parentLines.length; i++) {
    const parentLine = parentLines[i]
    result.push(parentLine)
    const itsChildrenLines = childrenLines.filter(
      (child) => child.parentLineId === parentLine.id
    )
    if (isMerge && itsChildrenLines.length === 1) {
      parentLine.data = itsChildrenLines[0].data
      continue
    }
    itsChildrenLines.forEach((child) => result.push(child))
  }

  return result
}

export function mapDataToLineData(
  data: HistoryLineData[],
  rowStyles?: { id: string; rowClassName: string }[]
) {
  let result: LineData[] = []

  data.forEach((line) => {
    const styleOfRow = rowStyles?.find((item) => item.id === line.id)
    const newLineData: LineData = {
      id: line.id,
      data: mapHistoryDataToLineData(line),
      rowClassName: styleOfRow?.rowClassName,
      parentLineId: line.parentLineId,
      childrenLineId: line.childrenLineId,
    }
    result.push(newLineData)
  })

  return result
}

export function mapHistoryDataToLineData(historyData: HistoryLineData) {
  const newLine: CellData[] = []

  for (const key in historyData)
    newLine.push({ name: key, value: `${(historyData as any)[key]}` })
  return newLine
}

export function getCellFromLine(name: string, line: LineData) {
  return line.data.find((cell) => cell.name === name)
}

export function hiddenCellBaseOnHeader(line: LineData, header: HeaderData[]) {
  const headerNames = header.map((item) => item.name)
  for (let i = 0; i < line.data.length; i++) {
    if (!headerNames.includes(line.data[i].name)) {
      line.data[i].cellClassName = 'hidden'
    }
  }
}
