import React, { ReactNode, useEffect, useRef } from 'react'
import { LineData } from './model'
import clsx from 'clsx'
import { CCell, CTr } from '../../stock/stockscreen/component/Wrapper'
import ARROW_DOWN from '../../../assets/icons/arrow-down.svg'
import ARROW_UP from '../../../assets/icons/arrow-up.svg'

const styleWhenExpanded = [
  { name: 'chrono', className: 'text-[#20458F] font-bold' },
  { name: 'date', className: 'text-[#20458F]' },
  { name: 'heure', className: 'text-[#20458F]' },
  { name: 'utilisateur', className: 'text-[#20458F]' },
]

function ParentLine(props: {
  line: LineData
  rowClassName?: string
  cellClassName?: { name: string }[]
  actions?: (id: string) => ReactNode
  expandColumnName?: string
  onCollapse?: (id: string) => void
  expanded?: boolean
  inVisibleCell?: string[]
  showArrow?: boolean
  showVerticalBorder?: boolean
  showHorizontalBorder?: boolean
}) {
  const onCollapse = props.onCollapse || function (id: string) {}
  const line = props.line
  const expandIcon = useRef<HTMLImageElement>(null)
  const { showVerticalBorder = false, showHorizontalBorder = false } = props

  useEffect(() => {
    if (!expandIcon.current) return

    if (props.expanded) expandIcon.current.style.transform = 'rotateX(180deg)'
    else expandIcon.current.style.transform = 'rotateX(-180deg)'
  }, [props.expanded])
  return (
    <CTr className={clsx(props.rowClassName, 'table-row')}>
      {
        <CCell
          className={clsx(
            `${showHorizontalBorder ? 'bd-bot-1-gray-history	' : ''}`,
            `${
              showVerticalBorder
                ? `bd-right-cell-table border-children-cell-table`
                : ''
            }`,
            'text-center px-0 bg-[#F9F9F9]'
          )}
        >
          {props?.showArrow &&
            line.childrenLineId &&
            line.childrenLineId?.length > 0 && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => onCollapse(props.line.id)}
                src={props?.expanded ? ARROW_UP : ARROW_DOWN}
                height={10}
              />
            )}
        </CCell>
      }
      {line.data.map((item, idx) => (
        <CCell
          key={item.name}
          className={clsx(
            `bg-[#F9F9F9]`,
            `${showHorizontalBorder ? 'bd-bot-1-gray-history	' : ''}`,
            `${
              showVerticalBorder
                ? `bd-right-cell-table border-children-cell-table`
                : ''
            }`,
            item.cellClassName,
            props.expanded && props.showArrow
              ? styleWhenExpanded.find((style) => style.name === item.name)
                  ?.className
              : ''
          )}
        >
          {props.inVisibleCell?.includes(item.name) ? '' : item.value}
        </CCell>
      ))}

      <CCell
        className={clsx(
          `bd-left-header-history-table`,
          `bg-[#F9F9F9]`,
          `${showHorizontalBorder ? 'bd-bot-1-gray-history	' : ''}`,
          `${
            showVerticalBorder
              ? `bd-right-cell-table border-children-cell-table`
              : ''
          }`
        )}
      >
        {props.actions && props.actions(props.line.id)}
      </CCell>
    </CTr>
  )
}

export default ParentLine
