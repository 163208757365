import { ReactElement, useEffect, useState } from 'react'
import IITable from './IITable'
import fakeData from './fake-data.json'
import { HeaderData, HistoryLineData, LineData } from './model'

import dayjs from 'dayjs'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'

const initData = fakeData as unknown as HistoryLineData[]

type HistoryTableSort = {
  name: string
  onSort: (name: string) => void
  onRemoveSort: (name: string) => void
}

export default function HistoryTable(props: {
  initData: LineData[]
  forGeneral?: boolean
  sorts?: HistoryTableSort[]
  empty?: ReactElement
}) {
  const { forGeneral = false, sorts = [] } = props
  const [header, setHeader] = useState<HeaderData[]>(
    !forGeneral
      ? [
          {
            label: 'Chrono',
            name: 'chrono',
            headerStyle: {
              headerCellClassName:
                'text-left w-1/12 text-[15px] text-[#808080]',
            },
            bodyStyles: {
              cellClassName: 'py-3 text-[15px] text-[#808080]',
            },
          },
          {
            label: 'Date',
            name: 'date',
            headerStyle: {
              headerCellClassName: 'text-left w-1/12',
            },
            bodyStyles: {
              cellClassName: 'w-1/12 py-3 text-[15px] text-[#808080]',
            },
            format: {
              formatFunction: (value) =>
                dayjs(Number(value)).format(t('date-format')),
            },
          },
          {
            label: 'Heure',
            name: 'heure',
            headerStyle: {
              headerCellClassName: '',
            },
            bodyStyles: {
              cellClassName: 'w-1/12 py-3 text-[15px] text-[#808080]',
            },
            format: {
              formatFunction: (value) =>
                dayjs(Number(value)).format(t('only-time-format')),
            },
          },
          {
            label: 'Utilisateur',
            name: 'utilisateur',
            headerStyle: {
              headerCellClassName: ' w-2/12',
            },
            bodyStyles: {
              cellClassName: 'w-2/12 py-3 text-[15px] text-[#808080]',
            },
          },
          {
            label: 'Evènement(s)',
            name: 'evenement',
            headerStyle: {
              headerCellClassName: ' w-2/12',
            },
            bodyStyles: {
              cellClassName: ' text-[15px] text-[#808080]',
            },
          },
          {
            label: 'Valeur',
            name: 'value',
            headerStyle: {
              headerCellClassName: ' w-3/12',
            },
            bodyStyles: {
              cellClassName: ' text-[15px] text-[#808080]',
            },
          },
        ]
      : [
          {
            label: 'Chrono',
            name: 'chrono',
            headerStyle: {
              headerCellClassName:
                'text-left w-1/12 text-[15px] text-[#808080]',
            },
            bodyStyles: {
              cellClassName: 'py-3 text-[15px] text-[#808080]',
            },
          },
          {
            label: 'Date',
            name: 'date',
            headerStyle: {
              headerCellClassName: 'text-left w-1/12',
            },
            bodyStyles: {
              cellClassName: 'w-1/12 py-3 text-[15px] text-[#808080]',
            },
            format: {
              formatFunction: (value) =>
                dayjs(Number(value)).format(t('date-format')),
            },
          },
          {
            label: 'Heure',
            name: 'heure',
            headerStyle: {
              headerCellClassName: 'text-left w-1/12',
            },
            bodyStyles: {
              cellClassName: 'w-1/12 py-3 text-[15px] text-[#808080]',
            },
            format: {
              formatFunction: (value) =>
                dayjs(Number(value)).format(t('only-time-format')),
            },
          },
          {
            label: 'Utilisateur',
            name: 'utilisateur',
            headerStyle: {
              headerCellClassName: 'text-left  w-2/12',
            },
            bodyStyles: {
              cellClassName: 'w-2/12 py-3 text-[15px] text-[#808080]',
            },
          },
          {
            label: 'Elément',
            name: 'object_search_type',
            headerStyle: {
              headerCellClassName: 'text-left  w-1/12',
            },
            bodyStyles: {
              cellClassName: 'w-1/12 py-3 text-[#808080] text-[15px]',
            },
          },
          {
            label: 'N°',
            name: 'object_number_or_sscc',
            headerStyle: {
              headerCellClassName: ' text-left w-1/12',
            },
            bodyStyles: {
              cellClassName:
                'w-1/12 py-3 text-[15px] text-[#0189E3] font-semibold',
            },
          },

          {
            label: 'Evènement(s)',
            name: 'evenement',
            headerStyle: {
              headerCellClassName: 'text-left  w-3/12',
            },
            bodyStyles: {
              cellClassName: 'text-[15px] text-[#808080]',
            },
          },
          {
            label: 'Valeur',
            name: 'value',
            headerStyle: {
              headerCellClassName: ' w-2/12',
            },
            bodyStyles: {
              cellClassName: ' text-[15px] text-[#808080]',
            },
          },
        ]
  )

  useEffect(() => {
    if (sorts.length === 0) return
    const headerClone = cloneDeep(header)
    sorts.forEach((sort) => {
      const name = sort.name
      const header = headerClone.find((item) => item.name === name)
      if (header) {
        header.sort = sort
      }
    })

    setHeader(headerClone)
  }, [sorts])

  const onAnnuler = (id: string) => {
    console.log({ id })
  }

  return (
    <IITable
      empty={props.empty || <></>}
      styles={{
        header: {
          showBorder: false,
          background: forGeneral ? 'bg-[#FBFBFB]' : '',
        },
        body: {
          showHorizontalBorder: true,
          showVerticalBorder: false,
        },
      }}
      mergeParentAndChildIfOneChild
      expandColumnName="Détail"
      header={header}
      data={props.initData}
      inVisibleCellIfChild={[
        'chrono',
        'date',
        'heure',
        'utilisateur',
        'object_type',
      ]}
      actions={(id: string) => {
        return (
          <div className={` text-center py-4 `}>
            {/*<button*/}
            {/*  onClick={() => onAnnuler(id)}*/}
            {/*  className="border-red border-solid bg-red text-white hover:text-white text-sm font-semibold py-1 rounded-lg cursor-pointer relative"*/}
            {/*>*/}
            {/*  <img src={ANNULER_ICON} alt="" height={14} className="px-1" />*/}
            {/*  Annuler*/}
            {/*</button>*/}
          </div>
        )
      }}
    />
  )
}
