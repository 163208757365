import React, { ReactNode } from 'react'
import { LineData } from './model'
import { CCell, CTr } from '../../stock/stockscreen/component/Wrapper'
import clsx from 'clsx'

function ChildLine(props: {
  line: LineData
  rowClassName?: string
  cellClassName?: { name: string }[]
  actions?: (id: string) => ReactNode
  expandColumnName?: string
  isShow?: boolean
  inVisibleCell?: string[]
  showVerticalBorder?: boolean
  showHorizontalBorder?: boolean
}) {
  const line = props.line
  const { showVerticalBorder = false, showHorizontalBorder = false } = props
  return (
    <CTr
      className={clsx(
        props.rowClassName,
        props.isShow ? 'table-row' : 'hidden',
        'bg-white'
      )}
    >
      {
        <CCell
          className={clsx(
            `${showHorizontalBorder ? 'bd-bot-1-gray-history	' : ''}`,
            `${
              showVerticalBorder
                ? `bd-right-cell-table border-children-cell-table`
                : ''
            }`,
            'text-center',
            'px-0'
          )}
        ></CCell>
      }
      {line.data.map((item, idx) => (
        <CCell
          key={item.name}
          className={clsx(
            item.cellClassName,
            `${
              showVerticalBorder
                ? `bd-right-cell-table border-children-cell-table`
                : ''
            }`,
            `${showHorizontalBorder ? 'bd-bot-1-gray-history	' : ''}`
          )}
        >
          {props.inVisibleCell?.includes(item.name) ? '' : item.value}
        </CCell>
      ))}
      <CCell
        className={clsx(
          'bd-left-header-history-table ',
          `${showHorizontalBorder ? 'bd-bot-1-gray-history	' : ''}`,
          `${
            showVerticalBorder
              ? `bd-right-cell-table border-children-cell-table`
              : ''
          }`
        )}
      >
        {props.actions && props.actions(props.line.id)}
      </CCell>
    </CTr>
  )
}

export default ChildLine
