import React, { ReactElement, ReactNode, useState } from 'react'
import { CTable } from '../../stock/stockscreen/component/Wrapper'
import { Header } from './Header'
import { mergeDataWithHeader } from './func'
import { HeaderData, LineData } from './model'
import { cloneDeep } from 'lodash'
import ParentLine from './ParentLine'
import ChildLine from './ChildLine'

function IITable(props: {
  header: HeaderData[]
  data: LineData[]
  hideCols?: string[]
  actions?: (id: string) => ReactNode
  expandColumnName?: string
  inVisibleCellIfChild?: string[]
  mergeParentAndChildIfOneChild?: boolean
  styles?: {
    header?: {
      showBorder?: boolean
      background?: string
    }
    body?: {
      showVerticalBorder?: boolean
      showHorizontalBorder?: boolean
    }
  }
  empty?: ReactElement
}) {
  const [expandedList, setExpandedList] = useState<string[]>([])
  const onCollapse = (id: string) => {
    let expandedListClone = cloneDeep(expandedList)
    if (expandedListClone.includes(id))
      expandedListClone = expandedListClone.filter((item) => item !== id)
    else expandedListClone.push(id)

    setExpandedList(expandedListClone)
  }
  if (props.data.length > 0)
    return (
      <CTable className="overflow-auto">
        <Header
          style={{
            showBorder: props?.styles?.header?.showBorder,
            background: props.styles?.header?.background,
          }}
          header={props.header}
          hasAction={Boolean(props.actions)}
          expandColumnName={props.expandColumnName}
        />
        {mergeDataWithHeader(
          props.header,
          props.data,
          props.hideCols || [],
          Boolean(props.mergeParentAndChildIfOneChild)
        ).map((line, idx) => {
          let inVisibleCell: string[] = []
          const parentLineId = line.parentLineId
          const id = line.id

          if (!parentLineId) {
            const itsChildrenLines = props.data.filter(
              (child) => child.parentLineId === id
            )
            return (
              <ParentLine
                key={id}
                expanded={expandedList.includes(id || '')}
                line={line}
                onCollapse={onCollapse}
                actions={props.actions}
                showArrow={
                  itsChildrenLines.length > 1 ||
                  (itsChildrenLines.length === 1 &&
                    !Boolean(props.mergeParentAndChildIfOneChild))
                }
                showHorizontalBorder={props.styles?.body?.showHorizontalBorder}
                showVerticalBorder={props.styles?.body?.showVerticalBorder}
              />
            )
          }

          let isShow = true

          if (props.inVisibleCellIfChild)
            inVisibleCell = props.inVisibleCellIfChild
          if (expandedList.includes(parentLineId || '')) isShow = true
          else isShow = false

          return (
            <ChildLine
              showHorizontalBorder={props.styles?.body?.showHorizontalBorder}
              showVerticalBorder={props.styles?.body?.showVerticalBorder}
              inVisibleCell={inVisibleCell}
              key={id}
              isShow={isShow}
              line={line}
              actions={props.actions}
            />
          )
        })}
      </CTable>
    )

  return (
    <div>
      <CTable className="overflow-auto">
        <Header
          style={{
            showBorder: props?.styles?.header?.showBorder,
            background: props.styles?.header?.background,
          }}
          header={props.header}
          hasAction={Boolean(props.actions)}
          expandColumnName={props.expandColumnName}
        />
      </CTable>
      <div className="h-1"></div>
      {props.empty}
    </div>
  )
}

export default IITable
